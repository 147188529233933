import React from "react"
import "../../assets/scss/style.scss"
import "../../assets/scss/responsive.scss"
const IntroBanner = props => {
  return (
    <div className="article-header">
      <div className="container-sm">
        <div className="article-title">
          <h2 className="post-title">{props.title}</h2>
        </div>
      </div>
      <span className="post-date">
        <i class="bx bx-time mr-1"></i>
        {props.date}
      </span>
      <div className="card">
        <div className="article-banner-img">
          <img src={props.image} alt="intro-banner-image" />
        </div>
      </div>
    </div>
  )
}

export default IntroBanner
