import React from "react"
import { graphql } from "gatsby"
import Navbar from "../components/App/Navbar"
import Layout from "../components/App/Layout"
import IntroBanner from "../components/Articles/articleBanner"
import ReactMarkdown from "react-markdown"
import Footer from "../components/App/Footer"
import CTABanner from "../components/Common/CTABanner"
import SEO from "../components/App/SEO"

export const query = graphql`
  query($id: String!) {
    allStrapiArticle(filter: { id: { eq: $id } }) {
      edges {
        node {
          id
          Author
          Content
          Date
          Title
          updated_at
          subtitle
          banner {
            url
          }
        }
      }
    }
  }
`

function formatDate(date) {
  var d = new Date(date),
    month = "" + d.toLocaleString("default", { month: "long" }),
    day = "" + d.getDate(),
    year = d.getFullYear()

  if (day.length < 2) day = "0" + day

  return [day, month, year].join(" ")
}

const NewsArticle = ({ data }) => {
  var article = data.allStrapiArticle.edges[0].node
  return (
    <Layout>
      <SEO
        title={article.Title}
        description={article.subtitle}
        ogimage={article.banner[0].url}
      />
      <Navbar />
      <div className="article">
        <div className="container">
          <IntroBanner
            title={article.Title}
            date={formatDate(article.Date)}
            image={article.banner[0].url}
          />
          <div className="blog-post-container">
            <div className="row">
              <div className="col">
                <div>
                  <ReactMarkdown>{article.Content}</ReactMarkdown>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CTABanner />
      <Footer />
    </Layout>
  )
}

export default NewsArticle
